import classNames from 'classnames';
import { graphql, PageProps } from 'gatsby';
import { Fragment, useEffect, useState } from 'react';

import * as posthog from '../analytics/posthog';
import Alert from '../components/Alert';
import { BaseHead } from '../components/BaseHead';
import Footer from '../components/Footer';
import HeadlineWithBackground from '../components/HeadlineWithBackground';
import Jumbotron, { JumbotronButton } from '../components/Jumbotron';
import LinkSection from '../components/LinkSection';
import Navigation from '../components/Navigation';
import FileDownloads, { FileDownloadsClickHandler } from '../containers/FileDownloads';
import { FairConfigFragment } from '../fragments/FairConfigFragment';
import { getContactRoute } from '../routes';
import { getMediaQuery } from '../utils/breakpoints';
import { toSrcSet } from '../utils/image';

import * as styles from '../styles/pages/IndexPage.module.scss';

const trackClickJumbotronButton = posthog.createTrackEvent('start-page:jumbotron-button-click');

const trackClickContactLink = posthog.createTrackEvent('start-page:contact-page-link-click');

const trackClickFileDownloadButtonOnClick: FileDownloadsClickHandler = (event, { filename }) => {
    posthog.createTrackEvent('start-page:file-download-button-click', {
        file: filename
    });
};

type BackgroundImageSizes = {
    [size: `_${number}`]: {
        src;
    };
};

type BackgroundImage = {
    jpg: BackgroundImageSizes;
    webp: BackgroundImageSizes;
};

type IndexPageData = FairConfigFragment & {
    backgroundImageDesktop: BackgroundImage;
    backgroundImageMobile: BackgroundImage;
};

type IndexPageProps = PageProps<IndexPageData>;

export default function IndexPage({ data, location }: IndexPageProps) {
    const { backgroundImageMobile, backgroundImageDesktop } = data;

    const [contactSuccess, setContactSuccess] = useState(false);

    useEffect(() => {
        if (location.hash === '#__contact:success') {
            setTimeout(() => {
                setContactSuccess(true);
            }, 500);
            window.history.replaceState({}, '', window.location.href.replace('#__contact:success', ''));
        }
    }, [location.hash]);

    return (
        <Fragment>
            <Navigation />

            <Alert show={contactSuccess} autoClose onClose={() => setContactSuccess(false)}>
                Vielen Dank! Wir melden uns in Kürze bei Ihnen.
            </Alert>

            <Jumbotron
                title={
                    <Fragment>
                        Finden Sie genau die Talente,
                        <br />
                        die Sie suchen
                    </Fragment>
                }
                content={
                    <JumbotronButton
                        href="#download"
                        onClick={trackClickJumbotronButton}
                        className={styles.headerCallToActionButton}
                    >
                        Jetzt Infos herunterladen
                    </JumbotronButton>
                }
                contentClassName={styles.headerContent}
                backgroundImageSrc={backgroundImageDesktop.jpg._1.src}
                backgroundImageSources={[
                    {
                        media: getMediaQuery('lg'),
                        type: 'image/webp',
                        src: backgroundImageDesktop.webp._2.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.webp._2.src, '1x'],
                            [backgroundImageDesktop.webp._3.src, '3x'],
                            [backgroundImageDesktop.webp._4.src, '4x']
                        ])
                    },
                    {
                        media: getMediaQuery('lg'),
                        src: backgroundImageDesktop.jpg._2.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.jpg._2.src, '1x'],
                            [backgroundImageDesktop.jpg._3.src, '3x'],
                            [backgroundImageDesktop.jpg._4.src, '4x']
                        ])
                    },
                    {
                        media: getMediaQuery('md'),
                        type: 'image/webp',
                        src: backgroundImageDesktop.webp._1.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.webp._1.src, '1x'],
                            [backgroundImageDesktop.webp._2.src, '2x'],
                            [backgroundImageDesktop.webp._3.src, '3x']
                        ])
                    },
                    {
                        media: getMediaQuery('md'),
                        src: backgroundImageDesktop.jpg._1.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.jpg._1.src, '1x'],
                            [backgroundImageDesktop.jpg._2.src, '2x'],
                            [backgroundImageDesktop.jpg._3.src, '3x']
                        ])
                    },
                    {
                        media: getMediaQuery('sm'),
                        type: 'image/webp',
                        src: backgroundImageDesktop.webp._1.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.webp._1.src, '1x'],
                            [backgroundImageDesktop.webp._2.src, '2x'],
                            [backgroundImageDesktop.webp._3.src, '4x']
                        ])
                    },
                    {
                        media: getMediaQuery('sm'),
                        src: backgroundImageDesktop.jpg._1.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.jpg._1.src, '1x'],
                            [backgroundImageDesktop.jpg._2.src, '2x'],
                            [backgroundImageDesktop.jpg._3.src, '4x']
                        ])
                    },
                    {
                        media: getMediaQuery('xs'),
                        type: 'image/webp',
                        src: backgroundImageDesktop.webp._1.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.webp._1.src, '1x'],
                            [backgroundImageDesktop.webp._2.src, '3x']
                        ])
                    },
                    {
                        media: getMediaQuery('xs'),
                        src: backgroundImageDesktop.jpg._1.src,
                        srcSet: toSrcSet([
                            [backgroundImageDesktop.jpg._1.src, '1x'],
                            [backgroundImageDesktop.jpg._2.src, '3x']
                        ])
                    },
                    {
                        src: backgroundImageMobile.webp._1.src,
                        type: 'image/webp',
                        srcSet: toSrcSet([
                            [backgroundImageMobile.webp._1.src, '1x'],
                            [backgroundImageMobile.webp._2.src, '2x'],
                            [backgroundImageMobile.webp._3.src, '3x']
                        ])
                    },
                    {
                        src: backgroundImageMobile.jpg._1.src,
                        srcSet: toSrcSet([
                            [backgroundImageMobile.jpg._1.src, '1x'],
                            [backgroundImageMobile.jpg._2.src, '2x'],
                            [backgroundImageMobile.jpg._3.src, '3x']
                        ])
                    }
                ]}
            />

            <div className="container--sm">
                <h2 className={styles.bepaperless}>#paperless</h2>

                <p className="text-center">
                    Vielen Dank für Ihren Besuch an unserem Stand! Schön, dass Sie da sind. Auf dieser Seite finden Sie
                    alle wichtigen Informationen zu truffls.
                </p>

                <p className="text-center">
                    Wir setzen auf Nachhaltigkeit und verzichten auf Papier. Alles, was Sie benötigen, steht Ihnen hier
                    digital zum Download zur Verfügung.&nbsp;😊
                </p>
            </div>

            <span id="download" />
            <HeadlineWithBackground component="h2" background="grey">
                Unsere Infos zum Download
            </HeadlineWithBackground>

            <div className={classNames('container--sm', styles.callToActionButtonContainer)}>
                <FileDownloads onClick={trackClickFileDownloadButtonOnClick} />
            </div>

            <HeadlineWithBackground component="h2" background="grey">
                Wir freuen uns darauf, Sie kennenzulernen!
            </HeadlineWithBackground>

            <LinkSection
                href={getContactRoute()}
                linkText="Jetzt Kontaktformular ausfüllen"
                linkOnClick={trackClickContactLink}
            >
                Am Schreibtisch oder im ruhigen Büro ist es entspannter für Sie? Kein Problem! Auch nach der
                Veranstaltung können wir uns zu einem „digitalen Kaffee“ verabreden und austauschen.
            </LinkSection>

            <Footer />
        </Fragment>
    );
}

export const Head = BaseHead;

export const query = graphql`
    query IndexPage {
        ...FairConfigFragment
        backgroundImageDesktop: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "pages/index/header/default-2-dark.jpg" }
        ) {
            jpg: childImageSharp {
                _1: resize(width: 1400) {
                    src
                }
                _2: resize(width: 2800) {
                    src
                }
                _3: resize(width: 4200) {
                    src
                }
                _4: resize(width: 5600) {
                    src
                }
            }
            webp: childImageSharp {
                _1: resize(width: 1400, toFormat: WEBP) {
                    src
                }
                _2: resize(width: 2800, toFormat: WEBP) {
                    src
                }
                _3: resize(width: 4200, toFormat: WEBP) {
                    src
                }
                _4: resize(width: 5600, toFormat: WEBP) {
                    src
                }
            }
        }
        backgroundImageMobile: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "pages/index/header/mobile-2-dark.jpg" }
        ) {
            jpg: childImageSharp {
                _1: resize(width: 480) {
                    src
                }
                _2: resize(width: 960) {
                    src
                }
                _3: resize(width: 1440) {
                    src
                }
            }
            webp: childImageSharp {
                _1: resize(width: 480, toFormat: WEBP) {
                    src
                }
                _2: resize(width: 960, toFormat: WEBP) {
                    src
                }
                _3: resize(width: 1440, toFormat: WEBP) {
                    src
                }
            }
        }
    }
`;
